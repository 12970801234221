import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EsGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/eversoul/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_intro.jpg"
                alt="Introduction"
              />
            }
            updated
          />
          <CategoryCard
            title="Reroll & Wishlist guide"
            link="/eversoul/guides/reroll"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_reroll.jpg"
                alt="Reroll & Wishlist guide"
              />
            }
            updated
          />
          <CategoryCard
            title="Beginner's guide"
            link="/eversoul/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_beginner.jpg"
                alt="Beginner's guide"
              />
            }
          />
          <CategoryCard
            title="Tips and tricks"
            link="/eversoul/guides/tips-and-tricks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tips.png"
                alt="Tips and trick"
              />
            }
            updated
          />
          <CategoryCard
            title="Team building"
            link="/eversoul/guides/team-building"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Generic guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Ascension guide"
            link="/eversoul/guides/ascension"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_ascension.png"
                alt="Ascension guide"
              />
            }
          />
          <CategoryCard
            title="Monthly resource income (F2P)"
            link="/eversoul/guides/monthly-income"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_income.png"
                alt="Monthly resource income (F2P)"
              />
            }
          />
          <CategoryCard
            title="Introduction to PVP"
            link="/eversoul/guides/introduction-to-pvp"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_pvp.webp"
                alt="PVP Guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Bosses" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Charite - raid guide"
            link="/eversoul/guides/charite-raid"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_charite.jpg"
                alt="Charite - raid guide"
              />
            }
          />
          <CategoryCard
            title="Gaia - raid guide"
            link="/eversoul/guides/gaia-raid"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_gaia.png"
                alt="Gaia - raid guide"
              />
            }
          />
          <CategoryCard
            title="Ruthra - raid guide"
            link="/eversoul/guides/ruthra-raid"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/cat_rutha.png"
                alt="Ruthra - raid guide"
              />
            }
          />
          <CategoryCard
            title="Dark Knight - raid guide"
            link="/eversoul/guides/dark-knight-raid"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_dk.webp"
                alt="Dark Knight - raid guide"
              />
            }
          />
          <CategoryCard
            title="Subjugation - Vivienne"
            link="/eversoul/guides/subjugation-vivienne"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_viv.png"
                alt="Subjugation - Vivienne"
              />
            }
          />
          <CategoryCard
            title="Subjugation - Aira"
            link="/eversoul/guides/subjugation-aira"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_aira.png"
                alt="Subjugation - Aira"
              />
            }
          />
          <CategoryCard
            title="Subjugation - Adrianne"
            link="/eversoul/guides/subjugation-adrianne"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_adr.png"
                alt="Subjugation - Adrianne"
              />
            }
          />
        </Row>
        <SectionHeader title="Progress" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Campaign unlocks"
            link="/eversoul/guides/campaign-unlocks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_progress.png"
                alt="Campaign unlocks"
              />
            }
            updated
          />
          <CategoryCard
            title="Level requirements"
            link="/eversoul/guides/level-requirements"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_level.png"
                alt="Level requirements"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Eversoul | Prydwen Institute"
    description="List of guides for Eversoul Strategy RPG gacha game."
    game="eversoul"
  />
);
